export const ErrorMessage = () => {
	return (
		<div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
			<div className="bg-white p-8 rounded-xl shadow-lg text-center w-full max-w-lg">
				<h1 className="text-2xl font-bold text-red-500 mb-6">
					Error: Something Went Wrong
				</h1>
				<p className="text-gray-700 mb-4">
					We apologize for the inconvenience. Our team has been notified and is
					working on resolving the issue.
				</p>
				<p className="text-gray-700 mb-6">
					In the meantime, you may try the following:
				</p>
				<ul className="list-disc list-inside text-left text-gray-700 space-y-2 mb-6 p-10">
					<li>
						Refresh the page: Click the refresh button or press{' '}
						<code className="bg-gray-200 p-1 rounded">F5</code> to reload the
						page.
					</li>
					<li>
						Return to the homepage:{' '}
						<a href="/" className="text-blue-500 underline">
							Click here to go back to the homepage
						</a>
						.
					</li>
					<li>
						Contact Support: If the issue persists, please contact our support
						team with details of the problem.{' '}
						<a href="mailto:support@doreveal.com">
							Click here to email support
						</a>
					</li>
				</ul>
				<p className="text-gray-500">Thank you for your patience!</p>
			</div>
		</div>
	)
}
