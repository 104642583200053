import { ClerkApp } from '@clerk/remix'
import { rootAuthLoader } from '@clerk/remix/ssr.server'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import ReactSelect from 'react-select/creatable'

import type { LoaderFunction } from '@remix-run/node'

import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useParams,
	useRouteError,
} from '@remix-run/react'

import { GlobalLoading } from '~/components/GlobalLoading'
import { Toaster } from '~/components/ui/toaster'

import { ErrorMessage } from './components/ErrorMessage'
import styles from './tailwind.css?url'

export const links = () => [{ rel: 'stylesheet', href: styles }]

export const loader: LoaderFunction = args => rootAuthLoader(args)

export function Layout({ children }: { children: React.ReactNode }) {
	return (
		<html lang="en" className="h-full">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="icon" type="image/x-icon" href="/favicon.png" />
				<link
					href="https://cdn.jsdelivr.net/npm/quill@2.0.2/dist/quill.snow.css"
					rel="stylesheet"
				/>
				<Meta />
				<Links />
				<UmamiTracking code="0fc8f0e7-6ffb-4bff-b7a2-81356528d307" />
			</head>
			<body className="h-full">
				<GlobalLoading />
				{children}
				<ScrollRestoration getKey={loc => loc.pathname} />
				<Scripts />
			</body>
		</html>
	)
}

function App() {
	const { study_id } = useParams()
	return (
		<div className="min-h-full">
			<Outlet />
			{!study_id && <div className="p-4" />}
			<Toaster />
			{/* Added to load styles we want in intial render. TODO: remove the hack */}
			<ReactSelect
				className="mt-1 hidden"
				classNamePrefix="reveal-select"
				isMulti
			/>
		</div>
	)
}

function UmamiTracking({ code }: { code: string }) {
	return (
		<script
			async
			src={`https://um.doreveal.com/script.js`}
			data-website-id={code}
			data-cache="true"
			data-auto-track="true"
		></script>
	)
}

export const ErrorBoundary = () => {
	const error = useRouteError()
	captureRemixErrorBoundaryError(error)
	return <ErrorMessage />
}

export default ClerkApp(App)
